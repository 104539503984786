import { CREATE_USER, CREATE_USER_ERROR, LOGIN, LOGIN_ERROR, LOGOUT } from "../actions/types";

const isLoggedIn = JSON.parse(localStorage.getItem('loginUser'));

  console.log('isLoggedIn : ', isLoggedIn);

const initialState = {
    loading: false,
    status: isLoggedIn?.status ?? false,
    user: isLoggedIn?.user ?? {},
    msg: ''
};

const AuthReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOGIN:
            return {
                ...state,
                user: payload,
                msg: 'Logged In',
                status: true,
            }
        case LOGIN_ERROR:
            return {
                ...state,
                user: {},
                msg: payload.msg,
                status: payload.status,
            }
        case LOGOUT:
            return {
                ...state,
                user: {},
                msg: 'Logout Successful!',
                status: false,
            }
        case CREATE_USER:
            return {
                ...state,
                msg: 'User Created!',
                status: true,
            }
        case CREATE_USER_ERROR:
            return {
                ...state,
                msg: 'Error creating user!',
                status: false,
            }
        default:
            return {...state};
    }
}

export default AuthReducer;